<template>
  <div>
    <v-row align="start" justify="center">
      <v-col cols="12" lg="8">
        <v-toolbar tile flat color="transparent">
          <v-toolbar-title>
            Voicemail
          </v-toolbar-title>
          <v-spacer/>
          <v-btn fab small color="success" elevation="0" @click="Save"><v-icon>mdi-content-save</v-icon></v-btn>
        </v-toolbar>
        <div
            v-for="(option, index) of options"
            :key="index"
        >
          <v-card outlined class="pa-3 mb-4">
            <h4 :id="option.option" class="text-capitalize font-weight-light pl-3 mb-1">{{option.option}}</h4>
            <v-text-field
                :placeholder="option.option"
                filled
                rounded
                dense
                hide-details
                class="mb-2"
                v-model="config[option.option]"
            />
            <div class="pl-3">
              <div class="font-weight-light">{{option.hint}}</div>
              <div><span class="blue-grey--text">Default:</span> {{option.default}}</div>
              <div><span class="blue-grey--text">Example:</span> {{option.example}}</div>
              <div v-if="option.validValues">
                <span class="blue-grey--text">Vaild Values:</span>
                <v-chip
                    v-for="value of option.validValues"
                    :key="value"
                    color="primary"
                    class="mx-1"
                    small
                >{{value}}</v-chip>
              </div>
            </div>
          </v-card>
        </div>
      </v-col>
      <v-col cols="4">
        <v-card outlined
                :style="currentScroll > 10 ? 'position: fixed; top: 260px; width: 260px':''"
                class="hidden-md-and-down"
        >
          <v-card-title>Options</v-card-title>
          <v-card-text>
            <h3
                v-for="(item, index) of options"
                :key="index"
                @click="$vuetify.goTo(`#${item.option}`)"
                class="body-1"
                style="cursor: pointer"
            >{{index = index + 1}}: {{item.option}}</h3>
            <v-btn
                v-if="currentScroll > 10"
                color="success"
                style="margin-right: 60px"
                @click="Save"
                block
                class="my-2"
            >
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
            <v-btn
                v-if="currentScroll > 10"
                color="primary"
                @click="$vuetify.goTo(0)"
                block
            >
              <v-icon>mdi-arrow-up-bold</v-icon>
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AcrobitOption from "../../../../classes/AcrobitOption";

export default {
  name: "voicemail",
  props: {
    domain:{
      type: String,
      required: true,
    }
  },
  data() {
    return{
      config:{},
      currentScroll: 0,
      options:[
        new AcrobitOption({
          option: 'voiceMailNumber',
          default: '*69',
          description: 'The number which is dialed when the “voice mail” button is pressed.',
          example: '*69'
        }),
        new AcrobitOption({
          option: 'subscribeForVoicemail',
          default: '0',
          description: 'Set to send SUBSCRIBE for voice mail',
          example: '1',
          validValues: ['0', '1'],
        }),
      ]
    }
  },
  beforeMount() {
    this.$axios.get('acrobits/get-settings', {params:{domain: this.domain}}).then(res=>{
      this.config = Object.assign({}, res.data)
    })
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.updateScroll)
  },
  methods:{
    updateScroll() {
      this.currentScroll = window.scrollY
    },
    Save() {
      this.$emit('save-settings', this.config)
    }
  }
}
</script>

<style scoped>

</style>